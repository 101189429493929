import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';


import { toast } from 'react-hot-toast';

//import { createOrder } from '../../../../api/orders';

import { getSupplierWithContact } from 'api/suppliers';
import Loading from 'components/Loading';
import CTA from 'components/CTA';


import { getArrayOfErrors } from 'utils/errors';

import useStore from 'store';

import styles from './summary.module.scss';
import { useDispatch, useSelector } from 'react-redux';
//import { setOrderID, setOrdersAllowed } from '../../../../redux/slices/orderSlice';

import ProductReview from '../../../components/ProductReview2/ProductReview';
import { HomeLayouts } from '../../../components/layouts/HomeLayout';
import { setStep } from '../../../redux/newOrderSlice/nOrderSlice';
import { getCustom } from '../../../redux/customersSlice/thunks';
import { isDateAfter, formatDateForOrder, getInputDateSumm } from '../../../utils/dates';
import ProductReview2 from '../../../components/ProductReview2/ProductReview';
import { createOrderSupp } from '../../../api/orders';

const Summary = ({ state, changeStep, setState }) => {
  const { user, token } = useSelector((state) => state.auth)


  // const { restaurant, restaurantList, token, user } = useSelector((state) => state.auth)
  const [supplier, setSupplier] = useState(null);
  const { customers, isLoading, errorData } = useSelector((state) => state.customers)
  const store = useStore((state) => state);
  const [productl, setproductl] = useState([])
  const [date, setDate] = useState(getInputDateSumm());
  const [note, setNote] = useState('');

  const [params, setParams] = useState({
    query: '',
  });
  // const [restaurantList, setRestaurantList] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCustom(user?.supplierId, user?.id, params.query, token))
    filter
  }, [])



  const filter = () => {

    if (customers && customers.length > 0) {
      const customer = customers.find((customer) => {
        return customer.restaurantId === state.supplierId

      })
      setSupplier(customer)

    }

  }
  

  const confirmOrder = async () => {
    try {
      const scheduleDate = formatDateForOrder(date);

      // const productsL = state.products.filter((product) => {
      //   return product.requestedQuantity !== 0 && product.requestedQuantity !== '0.0' && product.requestedQuantity !== '0'
 
      // })
      const order = {
        restaurantId: state.supplierId,
        products: productl,
        scheduleDate,
        note:'',
        noteSupplier:note,
        subtotal: state.subtotal,
        iva: state.iva === '0.00' ? 0 : state.iva,
        total: state.total
      };
      const orderResponse = await createOrderSupp(order, user.supplier.id, user?.id, token);

      // dispatch(setOrdersAllowed(orderResponse.orders_allowed))
      setState({ ...state, orderId: orderResponse.id });
      dispatch(setStep({ step: 4, customer: state.supplierId }));

    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
        setDisabled(false)
      } else {
        toast.error('No se pudo crear la orden. Inténtelo más tarde');
        setDisabled(false)
      }
    }
  };


  const filterProduct = (produ) => {


    const prod = produ.map((product) => {

      return {
        id: product.productId,
        quantity: product.requestedQuantity
      }

    })

    setproductl(prod)

  }

  // useEffect(async () => {

  //  // const supplierData = await getSupplierWithContact(
  //     parseInt(restaurant),
  //     state.supplierId,
  //     token
  //   );
  //   setSupplier(supplierData);
  // }, [state.supplierId]);

  useEffect(() => {
    filterProduct(state.products)
    if (state.products.length === 0) {
      dispatch(setStep({ step: 2, customer: state.supplierId }));
    }
  }, [state.products]);



  // if (!supplier) {
  //   return <HomeLayouts>
  //     <Loading />
  //   </HomeLayouts>;
  // }


  return (


    <div className={styles.gridColors}>
      <div className={styles.header}>
        <Back className={styles.back} onClick={() => dispatch(setStep({ step: 2 }))} />
        <h1 className={styles.title}>Resumen</h1>
      </div>
      <div className={styles.content}>
        <div className={styles.leftSide}>
          <div
            style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
          >
            <ProductReview2
              restaurant={'47'}
              newOrder={true}
              allowChanges={true}
              state={state}
              prod={productl}
              setState={setState}
              origin="summary"
              shouldRemoveEmpty={true}
            />
            {/*<div className={styles.containerPrices}>
              {state.products.map((val, i) => (
                <div key={i} className={styles.subtitle}>
                  $26.00
                </div>
              ))}
            </div>*/}
          </div>
          <div
            style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
          >
            <button onClick={() => dispatch(setStep({ step: 2 }))} className={styles.addProducts}>
              + Añadir productos
            </button>

          </div>
          <div>
            <p className={styles.subtitle}>Añadir Nota</p>
            <textarea
              className={styles.note}
              rows="4"
              maxLength="255"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <CTA
              text="Confirmar Orden"
              onClick={() => {
                setDisabled(true);
                confirmOrder();

              }}
              isDisabled={!isDateAfter(date, new Date()) || disabled}
              view="mobile"
            />
          </div>
        </div>
        {
          customers && customers.length > 0 ?
            <div className={styles.rightSide}>
              <div className={styles.detailsContainer}>
                <div className={styles.field}>
                  <p className={styles.subtitle}>Comercio: </p>
                  <p className={styles.value} >
                    {customers.find(
                      (val) =>
                        val.restaurantId === parseInt(state.supplierId),
                    )?.restaurantname
                      || '-'}
                  </p>
                </div>
                <div className={styles.field}>
                  <p className={styles.subtitle}>Dirección de entrega: </p>
                  <p className={styles.restaurantName}>
                    {customers.find(
                      (val) =>
                        val.restaurantId === parseInt(state.supplierId),
                    )?.address || '-'}
                  </p>

                </div>
              </div>

              <div className={styles.detailsContainer}>
                <div className={styles.field}>
                  <p className={styles.subtitle}>Proveedor: </p>

                  <p className={styles.value}>{user?.supplier.name}</p>
                </div>
                <div className={styles.field}>
                  <p className={styles.subtitle}>Vendedor: </p>
                  <p className={styles.value}>{user?.name}</p>
                </div>
              </div>
              <div className={styles.detailsContainer}>
                <div className={styles.field}>
                  <p className={styles.subtitle}>Fecha de entrega: </p>
                  <input
                    className={styles.dateValue}
                    type="date"
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                    value={date}
                  />
                </div>
              </div>
              <CTA
                text="Confirmar Orden"
                onClick={() => {
                  setDisabled(true);
                  confirmOrder();
                }}
                isDisabled={!isDateAfter(date, new Date()) || disabled}
                view="desktop"
              />
            </div>

            : null
        }

      </div>
    </div>

  );
};

Summary.propTypes = {
  state: PropTypes.object.isRequired,
  // changeStep: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
};

export default Summary;
