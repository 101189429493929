import React from 'react';
import PropTypes from 'prop-types';
import QuantityModifier from '../QuantityModifier';
import QuantityAmount from '../QuantityAmount';
import styles from './productReview.module.scss';
import QuantityModifierOrd from '../QuantityModifierOrd/QuantityModifierOrd';
import { Box, Divider, Grid, Typography } from '@mui/material';

const ProductReview2 = ({ newOrder, allowChanges, state, setState, shouldRemoveEmpty = true }) => {



  return (
    <div className={styles.container}>
      {state.products.map((product, i) => {


        return (
          <>
            <Grid
              container
              justifyContent={'space-between'}
              alignItems={'center'}
             
                >

              <Grid
                padding={1}
                
                item
                xs={allowChanges && newOrder === undefined ? 6 : 7}
                md={allowChanges && newOrder === undefined ? 5 : 7 }
                sm={allowChanges && newOrder === undefined ? 6 : 7 }
              >
                <Typography  fontWeight={'bold'} sx={{ fontSize: { xs: 13, md: 14 } }} >
                  {product.productName === undefined ? product.name : product.productName}
                </Typography>
              </Grid>
              <Grid
                padding={1}
                
                item
                sm={allowChanges && newOrder === undefined ? 4.5 : 2.5 }
                xs={allowChanges && newOrder === undefined ? 6 : 3.5 }
                md={allowChanges && newOrder === undefined ? 5.2 : 3 }
              >
                {allowChanges && newOrder === undefined ? (
                  <QuantityModifier
                    product={product}
                    state={state}
                    setState={setState}
                    allowChanges={allowChanges}
                    quantityKey={
                      typeof product.confirmedQuantity === 'number'
                        ? 'confirmedQuantity'
                        : 'requestedQuantity'
                    }
                    shouldRemoveEmpty={shouldRemoveEmpty}
                  />
                ) : allowChanges && newOrder ?

                  (
                    <QuantityModifierOrd
                      product={product}
                      state={state}
                      setState={setState}
                      allowChanges={allowChanges}
                      quantityKey={
                        typeof product.confirmedQuantity === 'number'
                          ? 'confirmedQuantity'
                          : 'requestedQuantity'
                      }
                      shouldRemoveEmpty={shouldRemoveEmpty}
                    />
                  ) :
                  (
                    <QuantityAmount product={product} />
                  )}
              </Grid>
            
              {/* <div key={i} className={styles.product}>
                <Box>
                  <Typography fontWeight={'bold'} sx={{ fontSize:{xs:13, md:15 } }} >
                    {product.productName === undefined ? product.name : product.productName} HHHH
                    HHH HHHH PAPA PASTUSA KIIIIIIK KKKK JUAN
                  </Typography>
                  <p></p>
                </Box>
                {allowChanges && newOrder === undefined ? (
                  <QuantityModifier
                    product={product}
                    state={state}
                    setState={setState}
                    allowChanges={allowChanges}
                    quantityKey={
                      typeof product.confirmedQuantity === 'number'
                        ? 'confirmedQuantity'
                        : 'requestedQuantity'
                    }
                    shouldRemoveEmpty={shouldRemoveEmpty}
                  />
                ) : allowChanges && newOrder ?

                  (
                    <QuantityModifierOrd
                      product={product}
                      state={state}
                      setState={setState}
                      allowChanges={allowChanges}
                      quantityKey={
                        typeof product.confirmedQuantity === 'number'
                          ? 'confirmedQuantity'
                          : 'requestedQuantity'
                      }
                      shouldRemoveEmpty={shouldRemoveEmpty}
                    />
                  ) :
                  (
                    <QuantityAmount product={product} />
                  )}
              </div> */}

            </Grid>
            {i === state.products.length - 1 ? null :
              <Divider color='#cbc7c7' sx={{ opacity: 0.3 }} />

            }
          </>


        );
      })}
    </div>
  );
};

ProductReview2.propTypes = {
  allowChanges: PropTypes.bool.isRequired,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  origin: PropTypes.string,
  shouldRemoveEmpty: PropTypes.bool,
};

export default ProductReview2;
