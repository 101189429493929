import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { CTAMUI } from '../../../../components/CTA/CTAMUI'
import styles from '../../message.module.scss'
import { useState } from 'react'
import { dias, hours } from '../../../../utils/dates'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment/moment'
import { setData, setValidate } from '../../../../redux/templateSlice/templateSlice'
import { useEffect } from 'react'

export const SecondStep = () => {

    const [step, setStep] = useState(1)
    const { data } = useSelector((state) => state.template)
    const dispatch = useDispatch()
    const hoy = moment().format('YYYY-MM-DD')


    useEffect(() => {


        validate()

    }, [data])



    const handleStep = () => {

        if (step === 1) {
            setStep(step + 1)
            dispatch(setData({
                ...data,
                recurrent: true

            }))
        } else {
            setStep(step - 1)
            dispatch(setData({
                ...data,
                recurrent: false

            }))
        }

    }

    const handleOnchange = (e) => {

        dispatch(setData({
            ...data,
            [e.target.name]: e.target.value

        }))

    }


    const validate = () => {

        if (step === 1 && data.fromsend != '' && data.hoursend != '') {

            dispatch(setValidate(true))
            dispatch(setData(data))

        } else if (step === 2 && data.fromsend != '' && data.hoursend != '' && data.day != '' && data.tosend !== '') {
            dispatch(setValidate(true))
            dispatch(setData(data))

        } else {
            dispatch(setValidate(false))
        }

    }




    return (


        <div style={{ height: 540 }} >


            <Box>
                <Typography
                    fontWeight='bold'
                >
                    Recurrencia
                </Typography>

                <Box
                    sx={{ width: { md: '30%', xs: '100%' } }}

                    mt={3}
                >
                    <CTAMUI
                        onClick={handleStep}
                        color={step === 1 ? '#49A35B' : 'white'}
                        borderColor={'#49A35B'}
                        backgroundColor={step === 1 ? 'white' : '#49A35B'}
                        text={'Mensaje Recurrente'}
                    />
                </Box>



                <Box
                    mt={3}
                >

                    <Typography>
                        Seleccione el día de la semana y hora que desea enviar este mensaje.
                    </Typography>

                </Box>

                <Grid
                    mt={3}
                    container
                    gap={3}
                >
                    <Grid
                        xs={12}
                        item
                        md={4}
                    >
                        <Typography
                            mb={1}
                            fontWeight='bold'
                        >
                            {step === 1 ? 'Fecha de envío' : 'Día del envío'}
                        </Typography>
                        {
                            step === 1 ?
                                <input
                                    value={data?.fromsend}
                                    onChange={handleOnchange}
                                    type="date"
                                    id="start"
                                    className={styles.inputAddP}
                                    name={'fromsend'}
                                    min={hoy}
                                /> :

                                <Box height={50} border={1} borderRadius={1} borderColor='#e4e4e4 '   >
                                    <select

                                        value={data?.day}
                                        onChange={handleOnchange}
                                        name={'day'}
                                        className={styles.selectAddProd} >
                                        {dias.map((udm, i) => {
                                            return <option key={i} value={udm.value} >{udm.dia} </option>
                                        })}
                                    </select>

                                </Box>
                        }

                    </Grid>
                    <Grid
                        xs={12}
                        md={2}
                        item
                    >
                        <Typography
                            mb={1}
                            fontWeight='bold'
                        >
                            Hora
                        </Typography>
                        <Box height={50} border={1} borderRadius={1} borderColor='#e4e4e4 '   >
                            <select
                                onChange={handleOnchange}
                                value={data?.hoursend}
                                name={'hoursend'}
                                className={styles.selectAddProd} >
                                {hours.map((udm, i) => {
                                    return <option key={i} value={udm} >{udm} </option>
                                })}
                            </select>

                        </Box>
                        {/* <input
                        type="time"
                        id="start"
                        className={styles.inputAddP}
                        name="trip-start"
                        value="2018-07-22"
                        /> */}
                    </Grid>

                </Grid>
                {
                    step === 2 ?
                        <Box
                            mt={2}
                        >
                            <Grid
                                xs={12}
                                item
                                md={4}
                            >
                                <Typography
                                    mb={1}
                                    fontWeight='bold'
                                >
                                    Fecha de inicio
                                </Typography>
                                <input
                                    onChange={handleOnchange}
                                    type="date"
                                    id="start"
                                    value={data?.fromsend}
                                    className={styles.inputAddP}
                                    name={'fromsend'}
                                    min={hoy}
                                />
                            </Grid>
                            <Grid
                                mt={2}
                                xs={12}
                                item
                                md={4}
                            >
                                <Typography
                                    mb={1}
                                    fontWeight='bold'
                                >
                                    Fecha de conclusión
                                </Typography>
                                <input
                                    type="date"
                                    id="start"
                                    value={data?.tosend}
                                    className={styles.inputAddP}
                                    name={'tosend'}
                                    onChange={handleOnchange}
                                    min={data.fromsend}
                                />
                            </Grid>
                        </Box>
                        : null
                }
            </Box>
        </div>

    )
}
