import React, { useEffect, useState } from 'react'
import { HomeLayouts } from '../../components/layouts/HomeLayout'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './message.module.scss'
import { Box, Container, Grid, Typography } from '@mui/material'
import { IoChevronBack as Back } from '@react-icons/all-files/io5/IoChevronBack';
import CTARed from '../../components/CTARed'
import PopUp from '../../components/PopUp'
import { ConfirmationDelete } from '../../components/PopUp/components/DeleteMessage/ConfirmationDelete'
import { reemplazarVariables } from '../../utils/replaceValues'
import { useSelector } from 'react-redux'
import moment from 'moment'

export const DeleteMessage = () => {
    const { user } = useSelector((state) => state.auth)
    const navigate = useNavigate();
    const [templateFields, setTemplateFields] = useState([])
    const [plantilla, setplantilla] = useState('')
    const [showModal, setShowModal] = useState(false);
    const { state } = useLocation()

  
useEffect(() => {
  
   setplantilla(reemplazarVariables( state.template.template, state.template.templateFields, user )) 
   filtrar()
}, [])


    const filtrar = ( )=>{

        const updatedTemplateFields = state.template.templateFields.map((field) => {
            // Busca un valor en templateValues que coincida con el id de templateFields
            const matchingValue = state.template.templateValues.find((value) => value.templateFieldId === field.id);
      
            // Si se encuentra un valor coincidente, actualiza el campo
            if (matchingValue) {
              return { ...field, value: matchingValue.value };
            }
      
            // Si no se encuentra un valor coincidente, no hace cambios en el campo
            return field;
          });

          const val = updatedTemplateFields.filter((val)=>{

                return val.edit === true

          })

          setTemplateFields(val);

    }

   


    return (

        <HomeLayouts>
        <PopUp showModal={showModal} setShowModal={setShowModal}>
        <ConfirmationDelete 
            idTemplate={state.template.supplierTemplateId }
            name={state?.template?.name}
            setShowModal={setShowModal}
            // clientId
            // contactId
       
       />
            </PopUp>
            <Container>
                <Box padding={3}>
                    <Grid
                        display='flex'
                        flexDirection='row'
                        alignItems='center'
                        justifyContent='space-between'
                        container >
                        <Grid
                            alignItems='center'
                            display='flex'
                            item >
                            <Back
                                cursor={'pointer'}
                                fontSize={25}
                                className={styles.back}
                                onClick={() => navigate(`/mensajes`)}
                            />
                            <Typography
                                fontSize={28}
                                fontWeight='bold'
                            > Detalle del mensaje </Typography>
                        </Grid>
                        <Grid item  >
                            <CTARed

                                text='Eliminar Mensaje'
                                onClick={() => {
                                    setShowModal(true);

                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>

               

                <Grid
                    mt={4}
                    paddingLeft={4}
                    container

                >

                    <Grid
                        item
                        xs={12}
                        md={4}
                    >
                        <Typography
                            fontSize={18}
                            fontWeight='bold'
                        > Nombre del mensaje </Typography>

                        <Box mt={1} height={50} width={'90%'} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
                            <input
                                readOnly
                                name='code'
                                style={{ width: '100%' }}
                                type="text"
                                value={state?.template?.name}

                            />
                        </Box>


                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={4}
                    >
                        <Typography
                            fontSize={18}
                            fontWeight='bold'
                        > Recurrente </Typography>
                        <Box mt={1} height={50} width={'60%'} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
                            <input
                                readOnly
                                name='code'
                                style={{ width: '100%' }}
                                type="text"
                                value={state?.template?.recurrent ? 'Si' : 'No'}

                            />
                        </Box>

                    </Grid>


                </Grid>


                <Grid
                    mt={3}
                    paddingLeft={4}
                    container

                >

                    <Grid
                        item
                        xs={12}
                        md={12}
                    >
                        <Typography
                            fontSize={18}
                            fontWeight='bold'
                        > Plantilla </Typography>

                        <Box mt={1}  width={'90%'} padding={2} border={1} borderRadius={2} borderColor='#E9E7E2' >
                              
                                <p dangerouslySetInnerHTML={{ __html: plantilla }}></p>
                            {/* <input
                                readOnly
                                name='code'
                                style={{ width: '100%' }}
                                type="text"
                                value={plantilla}

                            /> */}
                        </Box>


                    </Grid>

                            {
                                templateFields.length > 0 ? 

                                templateFields.map((template, i)=>{
                                    return(

                                        <Grid
                                        key={i}
                                        mt={2}
                                        item
                                        xs={12}
                                        md={5.7}
                                    >
                                        <Typography
                                            fontSize={18}
                                            fontWeight='bold'
                                        > {template.label
                                        } </Typography>
                
                                        <Box mt={1}  width={'90%'} padding={2} border={1} borderRadius={2} borderColor='#E9E7E2' >
                                              
                                              <Typography> { template.value } </Typography>
                                          
                                        </Box>
                
                
                                    </Grid>
                                    )

                                })

                                :null
                            }

                           

                  



                </Grid>

               

                {
                    state?.template?.recurrent ?



                        <>
                            <Grid
                                mt={4}
                                paddingLeft={4}
                                container

                            >

                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                >
                                    <Typography
                                        fontSize={18}
                                        fontWeight='bold'
                                    > Día</Typography>

                                    <Box mt={1} height={50} width={'90%'} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
                                        <input
                                            readOnly
                                            name='code'
                                            style={{ width: '100%' }}
                                            type="text"
                                            value={state?.template?.friday ? 'Viernes ' : state?.template?.monday ? 'Lunes' : state?.template?.saturday
                                                ? 'Sábado ' : state?.template?.sunday ? 'Domingo' : state?.template?.thursday ? 'Jueves' : state?.template?.tuesday
                                                    ? 'Martes' : 'Miércoles '


                                            }

                                        />
                                    </Box>


                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                >
                                    <Typography
                                        fontSize={18}
                                        fontWeight='bold'
                                    > Hora </Typography>
                                    <Box mt={1} height={50} width={'60%'} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
                                        <input
                                            readOnly
                                            name='code'
                                            style={{ width: '100%' }}
                                            type="text"
                                            value={state?.template?.hours
                                            }

                                        />
                                    </Box>

                                </Grid>


                            </Grid>
                            <Grid
                                mt={4}
                                paddingLeft={4}
                                container

                            >

                                <Grid
                                     item
                                    xs={12}
                                    md={4}
                                >
                                    <Typography
                                        fontSize={18}
                                        fontWeight='bold'
                                    > Fecha de inicio</Typography>

                                    <Box mt={1} height={50} width={'90%'} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
                                        <input
                                            readOnly
                                            name='code'
                                            style={{ width: '100%' }}
                                            type="text"
                                            value={ moment(state?.template?.fromDate).format('DD-MM-YYYY')}

                                        />
                                    </Box>


                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                >
                                    <Typography
                                        fontSize={18}
                                        fontWeight='bold'
                                    > Fecha de conclusión </Typography>
                                    <Box mt={1} height={50} width={'60%'} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
                                        <input
                                            readOnly
                                            name='code'
                                            style={{ width: '100%' }}
                                            type="text"
                                            value={  moment(state?.template?.toDate).format('DD-MM-YYYY')}

                                        />
                                    </Box>

                                </Grid>


                            </Grid>
                        </>

                        :


                        <Grid
                            mt={4}
                            paddingLeft={4}
                            container

                        >

                            <Grid
                                item
                                xs={12}
                                md={4}
                            >
                                <Typography
                                    fontSize={18}
                                    fontWeight='bold'
                                > Fecha Programada</Typography>

                                <Box mt={1} height={50} width={'90%'} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
                                    <input
                                        readOnly
                                        name='code'
                                        style={{ width: '100%' }}
                                        type="text"
                                        value={ moment(state?.template?.fromDate).format('DD-MM-YYYY')}

                                    />
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={4}
                            >
                                <Typography
                                    fontSize={18}
                                    fontWeight='bold'
                                > Hora </Typography>
                                <Box mt={1} height={50} width={'60%'} padding={0.5} border={1} borderRadius={2} borderColor='#E9E7E2' >
                                    <input
                                        readOnly
                                        name='code'
                                        style={{ width: '100%' }}
                                        type="text"
                                        value={state?.template?.hours
                                        }

                                    />
                                </Box>

                            </Grid>


                        </Grid>



                }

<Box mt={6} paddingLeft={5}>
                    <Typography
                        fontSize={18}
                        fontWeight='bold'
                    > Clientes </Typography>
                </Box>

                <div

                    className={styles.scrollbarX}

                    mt={2} >


                    {
                        state?.template?.templateClients && state?.template?.templateClients.map((client, i) => {
                            return (
                                <Box
                                    key={i}
                                    borderRadius={2}
                                    mr={1}
                                    padding={1}
                                    bgcolor={'#F9F6F4'}
                                >
                                    <Typography> {client.name} </Typography>
                                </Box>

                            )
                        })

                    }

                </div>







            </Container>



        </HomeLayouts>


    )
}
