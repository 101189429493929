import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PopUp from '../../../../components/PopUp';
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';
import { toast } from 'react-hot-toast';
import { getOrderById, cancelOrder } from 'api/orders';
import printIcon from 'assets/print.svg';
import { getArrayOfErrors } from 'utils/errors';
import Loading from 'components/Loading';
import CTA from 'components/CTA';
import ProductReview2 from 'components/ProductReview2';
import { getDisplayDate } from 'utils/dates';

import styles from 'styles/common/summary.module.scss';

import useStore from 'store';
import PrintComponent from '../../../../components/PrintComponent/PrintComponent';
import { useReactToPrint } from 'react-to-print';
import { Box, Typography } from '@mui/material';
import CancelOrder from '../../../../components/PopUp/components/CancelOrder/CancelOrder';
import { HomeLayouts } from '../../../../components/layouts/HomeLayout';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorToken } from '../../../../redux/authSlice/authSlice';
import moment from 'moment';

const OrderDetails = () => {
  const componentRef = useRef();
  const { user, token } = useSelector((state) => state.auth)
  const { id, restaurant } = useParams();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [loading, setloading] = useState(true)
  const [showModal, setShowModal] = useState(false);
  const [order, setOrder] = useState(null);
  const [orderL, setOrderL] = useState([]);
  const [dateRequest, setDateRequest] = useState(null)
  const [dateschedule, setDateschedule] = useState(null)
  const [isSame, setIsSame] = useState(null)

  console.log(order)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const handleCancel = async () => {

    try {
      //idRestaurant, id, token
      const cancelledOrder = await cancelOrder(parseInt(restaurant), order.id, token, user?.id);

      toast.success('Orden cancelada');
      setOrder(cancelledOrder);
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);

        if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {

          dispatch(setErrorToken(true))

        } else {
          for (let i = 0; i < errors.length; i++) {
            toast.error(errors[i]);
          }
        }



      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  };

  useEffect(() => {


  }, [orderL])

  //id_user, idRestaurant, id, token
  useEffect(async () => {

    try {
      const order = await getOrderById(user?.id, restaurant, id, token);
      setOrder(order);
      const dateR = moment(order.requestDate).format('YYYY-MM-DD')
      const dateS = moment(order.scheduleDate).format('YYYY-MM-DD')
      const isSa = moment(dateR).isSame(dateS);
      setIsSame(isSa)
      setDateRequest(dateR)
      setDateschedule(dateS)
      const orL = [{ date: '0', orders: [] },]
      orL[0].orders.push(order)
      setOrderL(orL)
      setloading(false)


    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);

        if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {

          dispatch(setErrorToken(true))

        } else {
          for (let i = 0; i < errors.length; i++) {
            toast.error(errors[i]);
          }
        }



      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  }, [order?.status]);

  if (!order) return <HomeLayouts></HomeLayouts>;

  return (

    <HomeLayouts>
      {
        loading ? <Loading /> :

          <div className={styles.gridColors}>

            <PopUp setShowModal={setShowModal} showModal={showModal}>
              <CancelOrder
                setShowModal={setShowModal}
                suppliuer={order.restaurant.name}
                date={order.deliveryDate
                  ? getDisplayDate(order.deliveryDate)
                  : getDisplayDate(order.scheduleDate)}
                onClick={() => handleCancel(order.id)}
              />
            </PopUp>

            <div className={styles.header}>

              <Back className={styles.back} onClick={() => navigate('/ordenes')} />

              <h1 className={styles.title}>Detalle</h1>
              {
                order?.status != 'cancelada' ?
                  <Box
                    sx={{ display: { xs: 'flex', md: 'none' } }}

                    alignItems='center'
                    justifyContent='end' justifyItems='end' mb={-2.5} width={250} height={60} >
                    <button className={styles.printButton} onClick={handlePrint}>

                      <img height={22} src={printIcon} alt="printIcon" />

                    </button>
                  </Box>
                  : null

              }

            </div>

            <div className={styles.content}>

              <div className={styles.leftSide}>
                {
                  order?.status != 'cancelada' ?
                    <Box
                      sx={{ display: { xs: 'none', md: 'flex' } }}
                      alignItems='center' justifyContent='end' justifyItems='end' mb={-2.5}  >
                      <button className={styles.printButton} onClick={handlePrint}>

                        <img height={22} src={printIcon} alt="printIcon" />

                      </button>
                    </Box>
                    : null

                }

                <ProductReview2 allowChanges={false} state={order} setState={() => { }} />
                <div>
                  <p className={styles.subtitle}>Notas del Cliente</p>
                  <textarea className={styles.note} value={order.note} readOnly />
                  {
                    order.status === 'solicitada' ? null :
                      <>
                        <p className={styles.subtitle}>Notas del proveedor </p>
                        <textarea className={styles.note} value={order.noteSupplier} readOnly />
                      </>

                  }

                  {order.status === 'solicitada' && (
                    <>
                      <CTA
                        text="Confirmar Orden"
                        onClick={() => {
                          navigate(`/ordenes/recibir/${id}/${restaurant}`);
                        }}
                        view="mobile"
                      />
                      <CTA
                        text="Cancelar Orden"
                        onClick={() => {
                          setShowModal(true);
                        }}
                        view="mobile"
                        color="red"
                      />
                    </>
                  )}

                </div>
              </div>
              <div className={styles.rightSide}>
                <div className={styles.detailsContainer}>
                  {
                    isSame && order.status === 'solicitada' || isSame && order.status === 'aceptada' ?
                      <Box
                        padding={0.5}
                        mb={1}
                      >
                        <Typography fontSize={18} fontWeight={'bold'} color={'#FB8B24'} textAlign={'center'} >¡Atención! Órden generada para el mismo día.</Typography>
                      </Box> : null
                  }
                  <div className={styles.field}>

                    <p className={styles.subtitle}>Estado: </p>
                    <Box ml={1} paddingBottom={0.1} paddingLeft={1} paddingRight={1} borderRadius={3} bgcolor={order.status === 'solicitada' ? '#FAECCC' :
                      order.status === 'aceptada' ? '#D6E4EE' : order.status === 'entregada' ? '#DEECDC' : '#FAE3DE'} >
                      <Typography fontSize={13} > {order.status === 'solicitada' ? 'Solicitada' :
                        order.status === 'aceptada' ? 'Aceptada' : order.status === 'entregada' ? 'Entregada' : order.status === 'cancelada' ? 'Cancelada' : null} </Typography>
                    </Box>
                  </div>
                  <div className={styles.field}>
                    <p className={styles.subtitle}>Orden: </p>
                    <p className={styles.value}>{order.code}</p>
                  </div>
                  <div className={styles.field}>
                    <p className={styles.subtitle}>Comercio: </p>
                    <p className={styles.value}>{order.restaurant.name}</p>
                  </div>
                </div>

                <div className={styles.detailsContainer}>
                  <div className={styles.field}>
                    <p className={styles.subtitle}>Fecha de orden: </p>
                    <p className={styles.value}>{getDisplayDate(order.requestDate)}</p>
                  </div>
                  <div className={styles.field}>
                    <p className={styles.subtitle}>Fecha de entrega: </p>
                    <p className={styles.value}>
                      {order.deliveryDate
                        ? getDisplayDate(order.deliveryDate)
                        : getDisplayDate(order.scheduleDate)}
                    </p>
                  </div>
                  <div className={styles.field}>
                    <p className={styles.subtitle}>Horario de entrega: </p>
                    <p className={styles.value}>   {order.restaurant.hourStart} a {order.restaurant.hourEnd} </p>

                  </div>


                </div>

                {order.status === 'solicitada' && (
                  <>
                    <CTA
                      text="Confirmar Orden"
                      onClick={() => {
                        navigate(`/ordenes/recibir/${id}/${restaurant}`);
                      }}
                      view="desktop"
                    />
                    <CTA
                      text="Cancelar Orden"
                      onClick={() => {
                        setShowModal(true);
                      }}
                      view="desktop"
                      color="red"
                    />
                  </>
                )}


              </div>
            </div>

            <PrintComponent orders={orderL} ref={componentRef} />
          </div>


      }


    </HomeLayouts>


  );
};

export default OrderDetails;
