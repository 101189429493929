import React, { useEffect, useState } from 'react'
import styles from '../../pop-up.module.scss';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import CTA from '../../../CTA';
import CurrencyInput from 'react-currency-input-field';
import { getArrayOfErrors } from 'utils/errors';
import { useDispatch, useSelector } from 'react-redux';
import { getCustom } from '../../../../redux/customersSlice/thunks';
import { addPriceSupplier } from '../../../../api/products';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';


export const AddPrice = ({ product_id, product, setShowModal, supplier, user, token }) => {
    const { customers, isLoading, errorData } = useSelector((state) => state.customers)
    const [loading, setloading] = useState(false)
    const navigate = useNavigate();
    const [params, setParams] = useState({
        query: '',
    });

    const dispatch = useDispatch()
    const [values, setValues] = useState({
        base: false,
        restaurante: '',
        precio: ''

    })


    useEffect(() => {
        dispatch(getCustom(supplier, user, params.query, token))

    }, [])

console.log(values)

    const handleOnchege = (value, name) => {

        console.log(value, name)

        setValues(prev => ({
            ...prev,
            [name]: value

        }))


    }
    const handleOnchegeChec = (e) => {


        setValues({
            ...values,
            base: e.target.checked

        })


    }


    const addPrice = async () => {

        setloading(true)

        let data = {}

        if (values.base) {
            data = {
                price: values.precio,
                supplier: supplier
            }
        } else {
            data = {
                price: parseFloat(values.precio),
                supplier: supplier,
                restaurant: values.restaurante
            }
        }

        try {

            const update = await addPriceSupplier(product_id, data, token)
            toast.success('Precio agregado correctamente');
            setTimeout(() => {
                window.location.reload()
                //navigate('/catalogo')
                setloading(false)
            }, 500);

        } catch (error) {
            if (error.response) {
                const errors = getArrayOfErrors(error.response.data);

                if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {

                    dispatch(setErrorToken(true))
                    setloading(false)
                } else {
                    for (let i = 0; i < errors.length; i++) {
                        toast.error(errors[i]);
                    }
                    setloading(false)
                }
            } else {
                toast.error('Error interno. Inténtelo más tarde');
                setloading(false)
            }
        }


        setShowModal(false)

    }



    return (
        <div className={styles.content}>
            <div className={styles.header}>

                <h1 className={styles.title}>Añadir Precio</h1>
            </div>
            <Typography mt={-2} fontSize={21} fontWeight={'bold'} >{product}.</Typography>
            <Typography mt={-1}> Ingresa si el precio es especifico por restaurante o si es un precio base. </Typography>
            <Box mt={2} mr={12} display='flex' alignItems='center' >
                <input
                    onChange={handleOnchegeChec}
                    name='base'
                    type='checkbox'

                />
                <Typography fontSize={18} mr={2} ml={1.5} >Es Precio Base</Typography>
            </Box>

            {
                !values.base ?
                    <Box mt={1} >
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Seleccionar Restaurante</InputLabel>
                            <Select
                                label='Seleccionar Restaurante'
                                displayEmpty
                                name='restaurante'
                                value={values.restaurante}
                                onChange={(e)=>handleOnchege(e.target.value, 'restaurante') }
                                fullWidth
                                variant='outlined'
                            >
                                {
                                    customers && customers.length > 0 && customers.map((customer, i) => {

                                        return (
                                            <MenuItem key={i} value={customer.restaurantId}>
                                                {customer.restaurantname}
                                            </MenuItem>

                                        )

                                    })
                                }


                                {/* 
                    {users && users.map((us, i) => {

                        return (
                            <MenuItem key={i} value={us.id}>
                                {us.name}
                            </MenuItem>

                        )
                    }

                    )} */}
                            </Select>
                        </FormControl>
                    </Box> : null
            }
            <CurrencyInput
                className={styles.input}
                //style={{ padding:5, borderRadius:5, backgroundColor:'red' }}
                decimalSeparator="." 
                groupSeparator=","
                prefix='$'
                id="input-example"
                name="precio"
                placeholder="Ingresar Precio"
                defaultValue={values.precio}
                decimalsLimit={2}
                onValueChange={(value, name)=>handleOnchege(value, name) }
            />
       

            <div className={styles.bottomContainer}>

                <CTA text="Agregar Precio" isDisabled={values.precio === ''} onClick={addPrice} />
            </div>
        </div>
    )
}
