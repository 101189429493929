import { Box, Grid, Typography } from '@mui/material'
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';
import styles from './customer.module.scss';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SummaryCTA from 'components/SummaryCTA';
import { setStep } from '../../redux/newOrderSlice/nOrderSlice';
import SearchBar from '../../components/SearchBar/SearchBar';
import { getproducts } from '../../redux/productsSlice/thunks';
import { CardProduct } from './components/CardProduct';
import { getCategoAll } from '../../redux/categories/thunks';

export const Products = ({ state, setState }) => {
  const dispatch = useDispatch()
  const { products, isLoading } = useSelector((state) => state.products)
  const { categoriesAll } = useSelector((state) => state.categories)
  const [active, setActive] = useState(0)
  const { user, token } = useSelector((state) => state.auth)
  const { step, customer, name } = useSelector((state) => state.ordern)
  const [params, setParams] = useState({
    page: 1,
    limit: 100,
    query: '',
  });


  const changeStep = () => {
 
    dispatch(setStep({ step: 3, customer: state.supplierId }))
  }
  const selectCateg = (id) => {
    
    if (id === 0) {
        setActive(id)
        dispatch(getproducts(token, user?.supplierId, params.page, params.limit, params.query))
    } else {
        dispatch(getproducts(token, user?.supplierId, params.page, params.limit, params.query, id ))
        setActive(id)
    }

}

useEffect(() => {
  dispatch(getproducts(token, user?.supplierId, params.page, params.limit, params.query))
}, [params])


  useEffect(() => {
   
    dispatch(getCategoAll(user?.supplierId, token))
  }, [])

  return (

    <Box
      sx={{paddingX:{xs:2, md:18   }, paddingY:3}}
    
      
    >

      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box

          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}

        >
          <Back className={styles.back} onClick={() => { dispatch(setStep({ step:1, customer:'' })), setState({
      supplierId: '',
      products:  [],
      note:  '',
      orderId: null,
      subtotal: 0,
      iva: 0,
      total: 0
    }) }} />
          <Typography variant='h1' fontWeight={'bold'} fontSize={31}  >Nueva Orden </Typography>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
        >

          <SearchBar
            name="query"
            query={params.query}
            placeholder="Buscar proveedor"
            setQuery={(val) => {
              setParams({ ...params, query: val });
            }}
          />
          <SummaryCTA products={state.products} changeStep={changeStep} />
        </Box>
      </Box>
      <Box

        mt={8}
      >
        <Typography variant='h1' fontWeight={'bold'} fontSize={22}  >{name} </Typography>
      </Box>

      <Box mt={2}>
        <div className={styles.categoriesContainer}>

          <div
            onClick={() => selectCateg(0)}
            className={active === 0 ? styles.selected : styles.category}>
            todas
          </div>

          {
            categoriesAll && categoriesAll.map((categoria, i) => {
              return (

                <div
                  onClick={() => selectCateg(categoria.id)}
                  key={i}
                  className={active === categoria.id ? styles.selected : styles.category}>
                    <Typography> {categoria.name}</Typography>
                 

                </div>

              )
            })
          }
        </div>
      </Box>
      <Grid
        spacing={1}
        container
        mt={2}
      >
        {
           products && products.products?.map((product)=>{

            if (product.isActive) {
              return (
                <Grid
                  key={product.productId}
                  item
                  xs={12}
                  md={6}
                >
                  <CardProduct
                    state={ state } 
                    setState={setState}
                    product={ product }
                    name={product.productName}
                  />
                </Grid>
               
              )
              
            }else{
              return null
            }

           


           })
        }

       
      </Grid>


    </Box>
  )
}
