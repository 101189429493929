import React from 'react'

import styles from '../../pop-up.module.scss';
import { Box, Button, Typography } from '@mui/material';
import { AiOutlineUpload } from '@react-icons/all-files/ai/AiOutlineUpload';
import { useState } from 'react';
import readXlsxFile from 'read-excel-file'
import { useDispatch, useSelector } from 'react-redux';
import { getModelFile } from '../../../../redux/inputFileSlice/thunks';
import { clearModel } from '../../../../redux/inputFileSlice/inputFileSlice';

export const ImportFile = ({ setShowModal }) => {


    const [file, setFlie] = useState()
    const dispatch = useDispatch()
   



    const handleOnchange = (e) => {

        setFlie(e.target.files[0])
     

    }

    const cerrar = () => {


       dispatch( clearModel())

       setTimeout(() => {
        dispatch(getModelFile(file))
        setShowModal(false)
        
       }, 100);

      



    }


    return (


        <div className={styles.content}>
            <h1 className={styles.title}>Importar archivo</h1>
            <Button

                sx={{
                    padding: 1.5, borderRadius: 8,
                    fontSize: 18
                }}
                variant="contained"
                component="label"
            >
                Cargar archivo
                <AiOutlineUpload style={{ marginLeft: 10 }} fontSize={22} />
                <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    type="file"
                    name='excel'
                    hidden
                    onChange={handleOnchange}
                />
            </Button>

            <Typography textAlign={'center'} >{file && `${file.name}`}</Typography>

            <Box


            >
                {
                    file ?
                        <Button
                            onClick={cerrar}
                            fullWidth
                            variant='outlined'
                            sx={{
                                padding: 1.3, borderRadius: 8,

                            }}

                        >
                            Confirmar
                        </Button>

                        : null
                }

            </Box>

        </div>

    )
}



campos: [
    {
        id: 1,
        type: 'code',
        name: 'code',
        labeCodigo: 'Código',


    },
    {
        id: 2,
        type: 'product',
        name: 'name',
        labeProduct: 'Nombre producto',

    },
    {

        id: 3,
        type: 'udm',
        name: 'measurementUnit',
        labelUdm: 'UDM',

    },
    {
        id: 4,
        type: 'category',
        name: 'subcategoryId',
        labelCategory: 'Categoría'
    }

]