





import { createSlice } from "@reduxjs/toolkit";





const initialState = {

    step:1,
    customer:'',
    name:'',
    state:{}
        
  
} 



 export const  orderNSlice = createSlice({

    name:'ordern',
    initialState:initialState,
    reducers:{
    
        setStep: (state, action )=>{
                            
            state.step=action.payload.step,
            state.customer=action.payload.customer,
            state.name=action.payload.name         
            
        },
        clearOrderN: (state )=>{
            state.step=1,
            state.customer='',
            state.name=''           
            
        },
   
    }


})

export const {setStep,  clearOrderN, } = orderNSlice.actions




