import React from 'react'
import { HomeLayouts } from '../../components/layouts/HomeLayout'
import CTA from 'components/CTA';
import SearchBar from 'components/SearchBar';
import styles from './catalog.module.scss';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Container, Divider, Grid, TablePagination, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getCategoAll } from '../../redux/categories/thunks';
import { useEffect } from 'react';
import { getproducts } from '../../redux/productsSlice/thunks';
import Loading from '../../components/Loading/Loading';
import PopUp from '../../components/PopUp';
import { ChangeStock } from '../../components/PopUp/components/changeStock/ChangeStock';
const options = [5, 10, 15, 20];
const columns = [
    {
        name: 'En Stock',
        size: 'small',
    },
    {
        name: 'Producto-Descripción',
        size: 'large',
    },
    {
        name: 'UDM',
        size: 'small',
    },
    // {
    //     name: 'Precio Unitario',
    //     size: 'medium',
    // },
    {
        name: 'Categoria',
        size: 'small',
    },
    {
        name: ' ',
        size: 'small',
    },
];



export const Catalog = () => {
    const { user, token } = useSelector((state) => state.auth)
    const { categoriesAll } = useSelector((state) => state.categories)
    const { products, isLoading } = useSelector((state) => state.products)
    const [active, setActive] = useState(0)
    const [page, setpage] = useState(0)
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false);
    const [product, setProduct] = useState(false);
    const [marcar, setmarcar] = useState(false);
    const [msg, setmsg] = useState('');
    const navigate = useNavigate();
    const [stock, setstock] = useState()
    const [params, setParams] = useState({
        page: 1,
        limit: 20,
        query: '',
    });




    const handleChangePage = (event, newPage) => {

        setpage(newPage);
        setParams({ ...params, page: newPage + 1 })

    };

    const handleChangeRowsPerPage = (event) => {
        setpage(0)
        setParams({ ...params, page: 1, limit: parseInt(event.target.value) });

    };


    useEffect(() => {
        dispatch(getproducts(token, user?.supplierId, params.page, params.limit, params.query))
        dispatch(getCategoAll(user?.supplierId, token))
    }, [])



    useEffect(() => {

        if (active === 0) {
            dispatch(getproducts(token, user?.supplierId, params.page, params.limit, params.query))
        } else {
            dispatch(getproducts(token, user?.supplierId, params.page, params.limit, params.query, active))
        }


    }, [params])



    const selectCateg = (id) => {



        if (id === 0) {
            setActive(id)
            dispatch(getproducts(token, user?.supplierId, params.page, params.limit, params.query))
        } else {
            dispatch(getproducts(token, user?.supplierId, params.page, params.limit, params.query, id))
            setActive(id)

        }


    }





    const handleONchemge = (e, column) => {

        if (!e.target.checked) {
            setProduct(column)
            setShowModal(true)
            setmarcar(true)
            setmsg(`Desea marcar el producto ${column.productName}  fuera de stock?.`)
        } else {
            setProduct(column)
            setShowModal(true)
            setmarcar(false)
            setmsg(`Desea marcar el producto ${column.productName}  en stock?.`)
        }


        setstock(column.id)



    }


    return (

        <HomeLayouts>
            {
                !products ? <Loading /> :
                    <>
                        <PopUp showModal={showModal} setShowModal={setShowModal} >
                            <ChangeStock
                                msg={msg}
                                producto={product}
                                supplier={user?.supplierId}
                                setShowModal={setShowModal}
                                marcar={marcar}
                            />
                        </PopUp>
                        <div className={styles.content} >

                            <div className={styles.header} >
                                <Box

                                    sx={{ alignItems: 'center', display: { sm: 'flex', md: 'flex', xs: 'block' } }}
                                    flexDirection='row'
                                    alignContent={'center'}
                                    justifyContent='space-between'
                                    display='flex' >


                                    <h1 className={styles.title}>Catálogo</h1>


                                    <Box flexDirection={'row'} sx={{ display: { sm: 'flex', md: 'flex', xs: 'block' } }} >
                                        <SearchBar
                                            query={params.query}
                                            setQuery={(val) => {
                                                setParams({ ...params, query: val });
                                            }}
                                            placeholder="Buscar"
                                        />
                                        {
                                            user?.secondaryRole === "ASESOR" ? null :
                                                <Box ml={2} width={220} sx={{ display: { xs: 'none', md: 'flex', sm: 'flex' } }}  >
                                                    <CTA
                                                        isDisabled={user?.secondaryRole === "ASESOR" ? true : false}
                                                        text="Añadir Productos"
                                                        onClick={() => {
                                                            navigate('/catalogo/agregar_productos');
                                                        }}
                                                    />
                                                </Box>
                                        }

                                    </Box>
                                </Box>
                            </div>



                        </div>



                        <Box mt={2}>
                            <div className={styles.categoriesContainer}>

                                <div
                                    onClick={() => selectCateg(0)}
                                    className={active === 0 ? styles.selected : styles.category}>
                                    todas
                                </div>

                                {
                                    categoriesAll && categoriesAll.map((categoria, i) => {
                                        return (

                                            <div
                                                onClick={() => selectCateg(categoria.id)}
                                                key={i}
                                                className={active === categoria.id ? styles.selected : styles.category}>

                                                {categoria.name}

                                            </div>

                                        )
                                    })
                                }
                            </div>
                        </Box>

                        <Container
                            maxWidth='lg'

                        >
                            <Grid
                                sx={{ padding: { xs: 0, md: 3 } }}

                                container
                                mt={2} >

                                <Grid
                                    borderRadius={3}
                                    border={0.5}
                                    borderColor={'#e4e4e4'}
                                    display='flex'
                                    flexDirection={'column'}
                                    container
                                >
                                    <Grid
                                        sx={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                                        bgcolor={'#e9e7e2'}
                                        padding={2}
                                        display='flex'
                                        flexDirection={'row'}
                                        container
                                    >
                                        {columns.map((column, i) => {
                                            return (
                                                <Grid

                                                    item
                                                    md={i === 0 ? 2 : i === 1 ? 3.2 : i === 2 ? 2.4 : i === 3 ? 2.2 : i === 4 ? 2.2 : null}
                                                    xs={i === 0 ? 3 : i === 1 ? 3 : i === 2 ? 3 : i === 3 ? 2 : i === 4 ? 1 : null}
                                                    key={`${i}_${column}`}

                                                >
                                                    <Typography
                                                        sx={{ fontSize: { xs: 16, md: 18 } }}

                                                        fontWeight={'bold'}
                                                    >  {column.name} </Typography>
                                                </Grid>
                                            );
                                        })}

                                    </Grid>

                                    {
                                        products && products?.products.map((column, i) => {

                                            return (
                                                <Grid
                                                    key={i}
                                                    alignItems='center'
                                                    padding={2}
                                                    sx={{ border: { xs: 1, md: 0 }, borderColor: { xs: '#e9e7e2', md: null } }}
                                                    display='flex'
                                                    flexDirection={'row'}
                                                    container
                                                >

                                                    {
                                                        user?.secondaryRole === "ASESOR" ?

                                                            <Grid item md={2} xs={3} >
                                                                <input className={styles.checkbox} type="checkbox" checked={column.isActive} readOnly />
                                                            </Grid>
                                                            :
                                                            <Grid item md={2} xs={3}>
                                                                <input
                                                                    name={column.productName}
                                                                    onChange={(e) => handleONchemge(e, column)}

                                                                    checked={column.isActive}
                                                                    value={column.isActive}
                                                                    className={styles.checkbox}
                                                                    type="checkbox"
                                                                />
                                                            </Grid>
                                                    }

                                                    <Grid item md={3.2} xs={3} display='flex' flexDirection='column' >
                                                        <Typography fontSize={16} > {column.productName} </Typography>
                                                        <Typography fontSize={12} color='#b0b0b0' > {column.code} </Typography>
                                                    </Grid>
                                                    <Grid item md={2.4} xs={3} >
                                                        <Typography fontSize={16} > {column.measurementUnit} </Typography>
                                                    </Grid>
                                                    <Grid item md={2.2} xs={3}>
                                                        <Typography fontSize={16} > {column.categoryName} </Typography>
                                                    </Grid>
                                                    <Grid item md={2.2} xs={12} display='flex' justifyContent='center' >

                                                        {
                                                            user?.secondaryRole === "ASESOR" ? null :
                                                                <Button

                                                                    onClick={user?.secondaryRole === "ASESOR" ? null : () => navigate('/catalogo/producto', { state: { column } })}
                                                                    variant='contained'

                                                                    sx={{
                                                                        marginTop: { xs: 2, md: 0 },
                                                                        borderRadius: 5, backgroundColor: 'transparent', border: 1, borderColor: '#e9e7e2', color: 'black',
                                                                        '&:hover': {
                                                                            backgroundColor: 'white',
                                                                        },

                                                                    }} >Editar</Button>

                                                        }



                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }

                                </Grid>

                            </Grid>
                        </Container>





                        <Box mt={-2} padding={1} mb={4} >
                            <div className={styles.desktopOrdersContainer} >
                                <TablePagination
                                    component="div"
                                    labelRowsPerPage='Filas por página'
                                    rowsPerPageOptions={options}
                                    count={parseInt(products?.totalCount) || 0}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={parseInt(params.limit)}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </div>
                        </Box>

                    </>

            }

        </HomeLayouts>


    )
}
