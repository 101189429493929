import React, { useEffect, useState } from 'react'
import styles from './customer.module.scss';
import SearchBar from '../../components/SearchBar/SearchBar';
import { Box, Grid, Typography } from '@mui/material';
import { Card } from './components/Card';
import { useDispatch, useSelector } from 'react-redux';
import { getCustom } from '../../redux/customersSlice/thunks';
import { setStep } from '../../redux/newOrderSlice/nOrderSlice';

export const Customers = ({ state, setState }) => {
    const { customers, isLoading, errorData } = useSelector((state) => state.customers)
    const { user, token } = useSelector((state) => state.auth)
    const [params, setParams] = useState({
        query: '',
    });


    const dispatch = useDispatch()


    useEffect(() => {

        dispatch(getCustom(user?.supplierId, user?.id, params.query, token))

    }, [params.query])


    useEffect(() => {

        dispatch(getCustom(user?.supplierId, user?.id, params.query, token))

    }, [])



    const onClick = (customer, name) => {

    
        if (state.supplierId !== customer) {

            setState({
                supplierId: customer,
                products: [],
                note: '',
                orderId: null,
            });
            dispatch(setStep({ step: 2, customer: customer, name }))

        } else {
            setState({
                ...state,
                supplierId: supplier.id,
            });
            dispatch(setStep({ step: 2, customer: customer, name }))
        }
       

    }



    return (
        <div>

            <Box
                sx={{ display: { md: 'flex', xs: 'none' } }}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                paddingX={13}
                paddingY={2.5}

            >
                <Typography variant='h1' fontWeight={'bold'} fontSize={31}  >Nueva Orden </Typography>
                <SearchBar
                    name="query"
                    query={params.query}
                    placeholder="Buscar proveedor"
                    setQuery={(val) => {
                        setParams({ ...params, query: val });
                    }}
                />

            </Box>
            <Box
                mt={2}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                paddingX={13}

            >
                <Typography variant='h1' fontWeight={'bold'} fontSize={24}  >Seleccionar Cliente </Typography>
            </Box>
            <Box
                mt={2}
                sx={{ display: { md: 'none', xs: 'flex' } }}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                paddingX={2}

            >
                <SearchBar
                    name="query"
                    query={params.query}
                    placeholder="Buscar proveedor"
                    setQuery={(val) => {
                        setParams({ ...params, query: val });
                    }}
                />
            </Box>
            <Box
                sx={{ paddingX: { xs: 2, md: 13 } }}
                mt={2}
                paddingY={2}
            >
                {
                    customers && customers.length === 0 ?
                        <Box
                            sx={{ display: 'flex', flexDirection: 'column', padding: 2, height: '80%', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <Typography
                                textAlign={'start'}
                                fontWeight={'bold'}
                                marginBottom={2}
                                fontSize={18}
                                maxWidth={250}
                            >
                                Tu lista esta vacia.
                            </Typography>
                            <Typography
                                fontSize={18}
                                maxWidth={250}
                                marginBottom={6}
                            >
                                Aún no tienes clientes. Agrega uno nuevo a tu lista.
                            </Typography>
                            <img src={emptySupplier} alt="No suppliers" />
                        </Box> :
                        <>
                            <Grid
                                spacing={1}
                                container
                            >
                                {customers && customers.map((customer, i) => {
                                    return (
                                        <Grid
                                            key={i}
                                            md={4}
                                            sm={6}
                                            xs={12}
                                            item
                                        >
                                            <Card
                                                onclick={ ()=> onClick(customer.restaurantId, customer.restaurantname)}
                                                customer={customer.restaurantId}
                                                name={customer.restaurantname}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </>
                }
            </Box>

        </div>
    )
}
