import { Box, Button, Grid, Typography } from '@mui/material'
import styles from '../../message.module.scss'
import React, { useEffect, useState } from 'react'
import { CTAMUI } from '../../../../components/CTA/CTAMUI'
import useForm from '../../../../hooks/useForm'
import { useDispatch, useSelector } from 'react-redux'
import { setData, setValidate } from '../../../../redux/templateSlice/templateSlice'
import { reemplazarVariables } from '../../../../utils/replaceValues'



export const FirstStep = ({ templates, setFormData, formData, val, setVal, inputs, setInputs }) => {
    const { user } = useSelector((state) => state.auth)

    const dispatch = useDispatch()


    const handleInputChange = (e) => {


        if (e.target.name === 'templateId') {

            const ms = templates.filter((temp) => {
                return temp.id === parseInt(e.target.value)
            })



            if (ms.length > 0) {

                const filt = ms[0].templateFields.map((fit) => {
                    return {
                        label: fit.label,
                        value: fit.value
                    }

                })


                const msg = reemplazarVariables(ms[0].template, filt, user)



                setFormData({
                    ...formData,
                    msg: msg,
                    templateId: e.target.value,
                    text: false,
                })






                if (ms[0].templateFields.length > 0) {
                    const textFiled = []
                    const inpu = ms[0].templateFields.filter((inp) => {

                        return inp.edit === true

                    })


                    const filt = ms[0].templateFields.map((fit) => {
                        return {
                            label: fit.label,
                            value: fit.value
                        }

                    })


                    const msg = reemplazarVariables(ms[0].template, filt, user)



                    for (let i = 0; i < inpu.length; i++) {


                        textFiled.push({
                            id: inpu[i].id,
                            value: '',
                            name: inpu[i].abv,
                            label: inpu[i].label

                        })

                    }
                    if (textFiled.length > 0) {
                        let initialValues = [];
                        for (let i = 0; i < textFiled.length; i++) {
                            initialValues[textFiled[i].name] = textFiled[i].value;

                        }
                        setVal(initialValues)
                        setInputs(textFiled)
                        setFormData({
                            ...formData,
                            msg: msg,
                            templateId: e.target.value,
                            values: [],
                            text: true
                        })

                    } else {

                        setInputs(textFiled)
                    }

                } else {
                    setInputs([])

                }
            } else {



                setFormData({
                    ...formData,
                    msg: '',
                    templateId: 0,
                    text: null
                })
                setInputs([])

            }


        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            })

        }


    }

    useEffect(() => {

        n()

    }, [val])


    useEffect(() => {

        // dispatch(setData(formData))
        validate()

    }, [formData])

    const validate = () => {

        if (formData?.text === false && formData?.templateId !== '' && formData?.name !== '') {
            dispatch(setData(formData))
            dispatch(setValidate(true))
        } else if (formData.text === true && formData?.templateId !== '' && formData?.name !== '' && formData.values[0] != undefined) {
            dispatch(setData(formData))
            dispatch(setValidate(true))
        } else {
            dispatch(setValidate(false))
        }


    }



    const handleOnchangeValues = (e, input, i) => {

        // console.log(input)

        const newArray = [...val];
        newArray[i] = {
            value: e.target.value,
            template_field_id: input.id,
        };
        setVal(newArray);

        // setVal({
        //     ...val,
        //     [e.target.name]: e.target.value
        // })

        //n()
    }




    const n = () => {

        // const va = Object.values(val)

        // let infoVal = []
        // const ff = []

        // // for (let i = 0; i < va.length; i++) {

        // //     if (va[i] === '') {
        // //         infoVal = []
        // //     } else[
        // //         infoVal.push(va[i])
        // //     ]


        // // }


        // for (let i = 0; i < inputs.length; i++) {



        //     ff.push({
        //         id: inputs[i].id,
        //         value: va[i],
        //         name: inputs[i].name,
        //         label: inputs[i].label

        //     })



        // }

        // const dat = ff.map((inp) => {



        //     if (inp.value !== '') {
        //         return {
        //             template_field_id: inp.id,
        //             value: inp.value,

        //         }

        //     }


        // })



        // setInputs(ff)
        setFormData({
            ...formData,
            values: val
        })



    }


  

    return (

        <Box>
            <Grid

                container
                alignItems='center'
            >


                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <Typography fontWeight='bold' > Nombre Mensaje Programado: </Typography>
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <input
                        value={formData.name}
                        onChange={handleInputChange}
                        name={'name'}
                        className={styles.inputAddP}
                        type="text"

                    />
                </Grid>

            </Grid>


            <Grid
                mt={4}
                container
                alignItems='center'
            >


                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <Typography fontWeight='bold' >Elegir Plantilla: </Typography>
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <select
                        //onChange={handleOnchege}
                        value={formData.templateId}
                        name='templateId'
                        onChange={handleInputChange}
                        className={styles.inputAddP} >

                        <option className={styles.optionInii} value={''} >Seleccionar plantilla </option>
                        {templates && templates.map((udm, i) => {
                            return <option key={i} value={udm.id} >{udm.name
                            } </option>
                        })}
                    </select>
                </Grid>
            </Grid>
            <Grid
                mt={4}
                container
                alignItems='center'
            >

                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <Typography fontWeight='bold' >Mensaje: </Typography>
                </Grid>
                <Grid
                    mt={1}
                    item
                    md={12}
                    xs={12}
                >


                    <Box height={120} border={1} padding={2} borderRadius={1} borderColor='#e4e4e4' >
                        <p dangerouslySetInnerHTML={{ __html: formData.msg }}></p>
                    </Box>



                </Grid>

            </Grid>

            {
                inputs.length < 1 ? null :

                    inputs.map((input, i) => {
                        return (

                            <Grid
                                key={i}
                                mt={4}
                                container
                                alignItems='center'
                            >
                                <Grid
                                    item
                                    xs={12}
                                    md={1.5}

                                >
                                    <Typography fontWeight='bold' > {input.label}: </Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={7}
                                    xs={12}
                                >

                                    <input
                                        value={val[i]?.value || ''}
                                        onChange={(e) => handleOnchangeValues(e, input, i)}
                                        name={input.name}
                                        className={styles.inputAddP}
                                        type="text"

                                    />
                                </Grid>

                            </Grid>

                        )
                    })


            }


            {/* <Grid
                    sx={{ marginTop: { xs: 2, md: 0 } }}
                    marginLeft={2}
                    item
                    md={3}
                    xs={12}
                >
                    <CTAMUI
                        text='Agregar texto dinámico'
                        backgroundColor='#49A35B'
                        borderColor='#49A35B'
                    />
                </Grid> */}



            <Grid
                mt={4}
                mb={10}
                container
                alignItems='center'
            >


                {/* <Grid
                    item
                    xs={12}
                    md={1}
                    marginRight={
                        6
                    }
                >
                    <Typography fontWeight='bold' >Texto 2: </Typography>
                </Grid> */}
                {/* <Grid
                    item
                    md={7}
                    xs={12}
                >
                    <input

                        name={'name'}
                        className={styles.inputAddP}
                        type="text"

                    />
                </Grid> */}
                {/* <Grid
                    sx={{ marginTop: { xs: 2, md: 0 } }}
                    marginLeft={2}
                    item
                    md={3}
                    xs={12}
                >
                    <CTAMUI
                        text='Agregar texto dinámico'
                        color='black'
                        backgroundColor='#E9E7E2'
                        borderColor='#E9E7E2'
                    />
                </Grid> */}
            </Grid>
        </Box>

    )
}
