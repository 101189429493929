import { Box, Typography } from '@mui/material'
import QuantityModifier from 'components/QuantityModifier';
import React from 'react'
import QuantityModifierOrd from '../../../components/QuantityModifierOrd/QuantityModifierOrd';

export const CardProduct = ({ name, product, state, setState }) => {


  return (
    <Box

  
    display='flex'
    flexDirection='column'
    padding={2}
    border={0.3}
    borderRadius={3}
    borderColor='#EEEEEE'
     >

    <Box display='flex' justifyContent='space-between' alignItems='center' >
      <Typography fontWeight='bold' fontSize={18}  > { name } </Typography>
      <QuantityModifierOrd
        product={product}
        state={state}
        setState={setState}
        allowChanges={true}
        newOrder={true}
        quantityKey="requestedQuantity"
      />
    </Box>


    {
      product.price === null || product.price === undefined ? null :
        <Typography fontSize={15} mt={-1} > ${`${product.price}/${product.measurementUnit}`} </Typography>
    }


  </Box>
  )
}
