import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import flyIcon from 'assets/fly.svg';
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';

import { getArrayOfErrors } from 'utils/errors';


import CTA from 'components/CTA';

import { toast } from 'react-hot-toast';
import useForm from 'hooks/useForm';

import styles from '../../pop-up.module.scss';
import { createCustomer, createCustomerExist, getRestaurantFiter, validateByIdentifier } from '../../../../api/restaurants';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-number-input';
import { setErrorToken } from '../../../../redux/authSlice/authSlice';
import { getSites } from '../../../../api/sites';

const AddSupplier = ({ setRerender, setShowModal }) => {
  const { user, token } = useSelector((state) => state.auth)
  const [step, setStep] = useState(1);
  const dispatch = useDispatch()
  const [countries, setCountries] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [cities, setCities] = useState(null);
  const [city, setCity] = useState('');
  const [provinces, setProvinces] = useState(null);
  const [country, setCountry] = useState('');
  const [province, setProvince] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [suppliers, setSuppliers] = useState([]);
  const [query, setQuery] = useState('');




  useEffect(() => {
    getS()
  }, [])

  const getS = async () => {

    const contr = await getSites('C', '', token)
    setCountries(contr)


  }
  const handleOnchangeCountry = async (value) => {

    const data = value.split(' ')

    const prov = await getSites('S', data[0], token)
    setCountry(value)
    setProvinces(prov)

  }
  const handleOnchangeProv = async (value) => {

    const data = value.split(' ')
    const cit = await getSites('CI', data[0], token)
    setProvince(value)
    setCities(cit)

  }
  const handleOnchangeCity = async (value) => {

    setCity(value)

  }

  const { formData, handleInputChange, isDisabled, setFormData } = useForm({
    id: '',
    restaurantIdentify: "",
    restaurantName: "",
    name: "",
    email: "",
    phone: "",
    address: '',

  });

  const [state, setState] = useState({
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
    userInput: '',
  });


  const stepComponents = {
    1: (
      <Step1
        city={city}
        cities={cities}
        province={province}
        provinces={provinces}
        handleOnchangeProv={handleOnchangeProv}
        handleOnchangeCity={handleOnchangeCity}
        countries={countries}
        handleOnchangeCountry={handleOnchangeCountry}
        country={country}
        setShowModal={setShowModal}
        idSupplier={user?.id}
        suppliers={suppliers}
        state={state}
        setState={setState}
        formData={formData}
        handleInputChange={handleInputChange}
        isDisabled={loading}
        setLoading={setLoading}
        setStep={setStep}
        step={step}
        setQuery={setQuery}
        setFormData={setFormData}
      />
    ),
    2: (
      <Step2
        suppliers={suppliers}
        state={state}
        phoneValue={phoneValue}
        setState={setState}
        formData={formData}
        country={country}
        province={province}
        city={city}

        setShowModal={setShowModal}
        setPhoneValue={setPhoneValue}
        handleInputChange={handleInputChange}
        isDisabled={loading}
        setLoading={setLoading}
        idSupplier={
          user?.id
        }
        setStep={setStep}
        step={step}
      />
    ),
    3: <Step3 navigate={navigate} setRerender={setRerender} setShowModal={setShowModal} />,
    // 4: <Step4 setRerender={setRerender} setShowModal={setShowModal} />,
  };

  useEffect(async () => {
    try {
      const data = await getRestaurantFiter(query, token);
      let suggestions = [];
      for (let i = 0; i < data.length; i++) {
        suggestions.push(data[i].name);
      }
      setSuppliers(suggestions);
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);

        if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {
          setShowModal(false)
          dispatch(setErrorToken(true))

        } else {
          for (let i = 0; i < errors.length; i++) {
            toast.error(errors[i]);
          }
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  }, [query]);

  return stepComponents[step];
};

const Step1 = ({
  provinces,
  city,
  cities,
  province,
  handleOnchangeProv,
  handleOnchangeCity,
  countries,
  handleOnchangeCountry,
  country,
  setShowModal,
  isDisabled,
  setLoading,
  idSupplier,
  suppliers,
  state,
  setState,
  formData,
  handleInputChange,
  setStep,
  step,
  setQuery,
  setFormData,
}) => {
  const { user, token } = useSelector((state) => state.auth)

  const validate = async () => {

    setLoading(true)

    const data = {
      identifier: formData.restaurantIdentify
    }


    const res = await validateByIdentifier(data, token)

    try {
      if (res === true) {
        createSupplier()
      } else {
        setStep(2);
      }
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);

        if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {
          setShowModal(false)
          dispatch(setErrorToken(true))
          setLoading(false)

        } else {
          for (let i = 0; i < errors.length; i++) {
            toast.error(errors[i]);
          }
          setLoading(false)
        }
      } else {

        toast.error('Error interno. Inténtelo más tarde');
        setLoading(false)
      }
    }





  }

  const createSupplier = async () => {


    const data = {

      restaurantId: formData.restaurantIdentify

    }


    try {

      const create = await createCustomerExist(idSupplier, data, token)

      setStep(3)
      setLoading(false)

    } catch (error) {


      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);

        if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {
          setShowModal(false)
          dispatch(setErrorToken(true))
          setLoading(false)
        } else {
          for (let i = 0; i < errors.length; i++) {
            toast.error(errors[i]);
          }
          setLoading(false)
        }
      } else {

        toast.error('Error interno. Inténtelo más tarde');
        setLoading(false)
      }

    }
  };


 

  return (
    <div className={styles.content}>
      <h1 className={styles.title}>Añadir Cliente</h1>
      <h2 className={styles.subtitle}>Por favor ingresa los datos de tu cliente.</h2>
      <input
        className={styles.input}
        type="text"
        placeholder="Razón Social"
        name="restaurantName"
        value={formData.restaurantName}
        onChange={handleInputChange}
        inputMode="text"
        autoComplete="text"
        autoCorrect="off"
      />

      {/* <AutoComplete
        suggestions={suppliers}
        placeholder="Razón Social"
        state={state}
        setState={setState}
        setQuery={setQuery}
        formData={formData}
        setFormData={setFormData}
      /> */}
      <input
        className={styles.input}
        type="text"
        placeholder="RUC"
        name="restaurantIdentify"
        value={formData.restaurantIdentify}
        onChange={handleInputChange}
        inputMode="numeric"
        autoComplete="number"
        autoCorrect="off"
      />
      <input
        className={styles.input}
        type="text"
        placeholder="Dirección(Opcional)"
        name="address"
        value={formData.address}
        onChange={handleInputChange}
        inputMode="text"
        autoComplete="text"
        autoCorrect="off"
      />

      <select
        value={country != null ? country : ''}
        name='pais'
        onChange={(e) => handleOnchangeCountry(e.target.value)}
        className={styles.inputAddP} >
        <option className={styles.optionInii} value={''} >Pais(Opcional) </option>
        {countries && countries.map((coun, i) => {
          return <option key={i} value={`${coun.id} ${coun.name}`} >{coun.name
          } </option>
        })}
      </select>
      <select
        value={province != null ? province : ''}
        name='provincia'
        onChange={(e) => handleOnchangeProv(e.target.value)}
        className={styles.inputAddP} >

        <option className={styles.optionInii} value={''} >Provincia(Opcional) </option>
        {provinces && provinces.map((prov, i) => {
          return <option key={i} value={`${prov.id} ${prov.name}`} >{prov.name
          } </option>
        })}
      </select>
      <select
        value={city != null ? city : ''}
        name='ciudad'
        onChange={(e) => handleOnchangeCity(e.target.value)}
        className={styles.inputAddP} >

        <option className={styles.optionInii} value={''} >Ciudad(Opcional) </option>
        {cities && cities.map((cit, i) => {
          return <option key={i} value={`${cit.id} ${cit.name}`} >{cit.name
          } </option>
        })}
      </select>

      <div className={styles.bottomContainer}>
        <div className={styles.stepsContainer}>
          <div className={`${styles.dot} ${step === 1 && styles.current}`}></div>
          <div className={`${styles.dot} ${step === 2 && styles.current}`}></div>
        </div>
        <CTA
          text={"Continuar"}
          isDisabled={formData.restaurantIdentify === '' || isDisabled}
          onClick={() => {
            if (formData.restaurantIdentify.length !== 13) {
              toast.error('El RUC debe tener 13 dígitos');
            } else if (formData.restaurantIdentify.length >= 13 && formData.restaurantName !== '') {
              validate()
            } else {
              toast.error('la razon social es requerida');

            }
          }}
        />
      </div>
    </div>
  );
};

const Step2 = ({ setShowModal, formData, handleInputChange, isDisabled, setStep, step, state, idSupplier, setPhoneValue, phoneValue, setLoading,

  country,
  province,
  city,

}) => {



  const { user, token } = useSelector((state) => state.auth)
  const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
  const [isEmail, setisEmail] = useState(false)
  const dispatch = useDispatch()
  const createSupplier = async () => {

    setLoading(true)

    const cit = city.split(' ')
    const coun = country.split(' ')
    const prov = province.split(' ')

    if (formData.email != '' && !emailRegex.test(formData.email)) {
      setisEmail(true)
    }else{
      setisEmail(false)
      const data = {

        address: formData.address,
        country: coun[1],
        state: prov[1],
        city: cit[1],
        restaurantIdentify: formData.restaurantIdentify,
        restaurantName: formData.restaurantName,
        name: formData.name,
        email: formData.email,
        phone: phoneValue
  
      }
  
      try {
        const create = await createCustomer(idSupplier, data, token)
 
       setStep(3)
       setLoading(false)
 
     } catch (error) {
 
       if (error.response) {
         const errors = getArrayOfErrors(error.response.data);
 
         if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {
 
           dispatch(setErrorToken(true))
           setLoading(false)
           setShowModal(false)
         } else {
           for (let i = 0; i < errors.length; i++) {
             toast.error(errors[i]);
           }
           setLoading(false)
         }
       } else {
         toast.error('Error interno. Inténtelo más tarde');
         setLoading(false)
       }
 
     }

    }

  

   
   


   
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <Back className={styles.back} onClick={() => setStep(1)} />
        <h1 className={styles.title}>Añadir Usuario Restaurante</h1>
      </div>
      <h2 className={styles.subtitle}>Por favor ingresa los datos del cliente.</h2>
      <input
        className={styles.input}
        type="text"
        placeholder="Nombre"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        autoComplete="name"
        autoCorrect="off"
      />
      <input
        className={styles.input}
        type='email'
        placeholder="Email(Opcional)"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        autoComplete="name"
        autoCorrect="off"
      />
      {
        isEmail ? 
        <p className={styles.textError} >El formato de email es incorrecto</p> : null
      }
      
      <PhoneInput
        onChange={setPhoneValue}
        value={phoneValue}
        placeholder="Teléfono"
        type="tel"
        inputMode="tel"
        autoComplete="tel"
        autoCorrect="off"
        className={styles.input}
        defaultCountry="EC"
      />
      
      <div className={styles.bottomContainer}>
        <div className={styles.stepsContainer}>
          <div className={`${styles.dot} ${step === 1 && styles.current}`}></div>
          <div className={`${styles.dot} ${step === 2 && styles.current}`}></div>
        </div>
        <CTA text="Continuar" isDisabled={formData.name === '' || phoneValue === '' ? true : false} onClick={createSupplier} />
      </div>
    </div>
  );
};

const Step3 = ({ setRerender, setShowModal, navigate }) => {
  return (
    <div className={styles.content}>
      <h1 className={styles.title}>Solicitud Recibida</h1>
      <h2 className={styles.subtitle}>
        Tu cliente ha recibido una solicitud para unirse a Cado. ¡Gracias por tu paciencia!
      </h2>
      <img width={'100px'} height={'50px'} className={styles.icon} src={flyIcon} alt="basket" />
      <div className={styles.bottomContainer}>
        <CTA
          text="Ver Mis Clientes"
          onClick={() => {
            setShowModal(false);
            window.location.reload();

          }}
        />
      </div>
    </div>
  );
};

// const Step4 = ({ setRerender, setShowModal }) => {
//   return (
//     <div className={styles.content}>
//       <h1 className={styles.title}>Solicitud Recibida</h1>
//       <h2 className={styles.subtitle}>
//         Nuestro equipo ha recibido tu solicitud para agregar a este proveedor y su
//         catálogo de productos. ¡Gracias por tu paciencia!
//       </h2>
//       <img className={styles.icon} src={paperPlane} alt="paper plane" />
//       <div className={styles.bottomContainer}>
//         <CTA
//           text="Ver Mis Proveedores"
//           onClick={() => {
//             setShowModal(false);
//             setRerender(true);
//           }}
//         />
//       </div>
//     </div>
//   );
// };

Step1.propTypes = {
  suppliers: PropTypes.array,
  state: PropTypes.object,
  setState: PropTypes.func,
  formData: PropTypes.object,
  handleInputChange: PropTypes.func,
  setStep: PropTypes.func,
  step: PropTypes.number,
  setQuery: PropTypes.func,
  setFormData: PropTypes.func,
};

Step2.propTypes = {
  formData: PropTypes.object,
  handleInputChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  setStep: PropTypes.func,
  step: PropTypes.number,
  state: PropTypes.object,
};

// Step3.propTypes = {
//   setRerender: PropTypes.func.isRequired,
//   setShowModal: PropTypes.func.isRequired,
// };

// Step4.propTypes = {
//   setRerender: PropTypes.func.isRequired,
//   setShowModal: PropTypes.func.isRequired,
// };

export default AddSupplier;
